import React, { Component } from 'react'
import styles from './index.module.scss'
import ProductItem from 'Components/UI/ProductItem'
import MainBtn from 'Components/UI/MainBtn'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import { navigate } from 'gatsby'

class FavoritesLayer extends Component {
  constructor(props) {
    super(props)
    this.parent = React.createRef()
    this.state = {
      products: []
    }
  }
  componentDidMount() {
    AppStore.on(AppConstants.ADD_TO_FAVORITES, this.updateCurrentState)
    AppStore.on(AppConstants.REMOVE_FROM_FAVORITES, this.updateCurrentState)
    AppStore.on(AppConstants.MOVE_FAVORITES_TO_BAG, this.updateCurrentState)
    window.addEventListener('resize', this.resize)
    this.updateCurrentState()
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.ADD_TO_FAVORITES, this.updateCurrentState)
    AppStore.off(AppConstants.REMOVE_FROM_FAVORITES, this.updateCurrentState)
    AppStore.off(AppConstants.MOVE_FAVORITES_TO_BAG, this.updateCurrentState)
    window.removeEventListener('resize', this.resize)
  }
  updateCurrentState = () => {
    const products = []
    const currentProducts = AppStore.getFavorites()
    currentProducts.forEach(item => products.push(item.product))
    this.setState({ products }, this.resize)
  }
  resize = () => {
    this.productItemRefs.forEach(product => product.current.resize())
  }
  onFavoriteToBagClick = (item) => {
    setTimeout(AppActions.moveFavoritesToBag, 0, { product: item })
  }
  onProductItemRemoveClick = (product) => {
    setTimeout(AppActions.removeFromFavorites, 0, product)
  }
  render() {
    const isCustomer = AppStore.userIsValid(AppStore.getCustomer())
    this.productItemRefs = []
    const products = this.state.products.map((product, index) => {
      const productRef = React.createRef()
      this.productItemRefs.push(productRef)
      return (
        <div key={`favorites-page-product-item-${product.id}-${index}`} className='u-col-6 u-col-lg-3'>
          <ProductItem
            baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL}
            data={{product}}
            index={index}
            ref={productRef}
            extraClasses={'u-down-scale--2'}
            showFavoriteIcon={false}
            showRemoveIcon={true}
            showOtherColors={false}
            showInfo={false}
            lockSale={!isCustomer}
            buttonText={'Add to bag'}
            pageToGo={product.permalink}
            buttonOnClick={this.onFavoriteToBagClick}
            removeOnClick={this.onProductItemRemoveClick}
            miniVersion={false}
          />
        </div>
      )
    })
    return (
      <div className={`${styles.productsContainer} u-container u-fit`}>
        <div className={`${styles.title} u-inline-block t-title-2 u-offset-md-1 u-pad-x--md`}>
          { this.state.products.length === 0 &&
          <div>
            <div>Your favorites list is empty</div>
            <div className='u-height--20' />
            <div className='u-height--only-lg--20' />
            <p className={`t-title-1`}>
              Click the icon <span>
                <svg className={`${styles.heartIcon} t-fill-color-black`}>
                  <use xlinkHref="#heart-icon" />
                </svg>
              </span> on any product to save it to your Favourites
            </p>
            <div className='u-height--60' />
            <div className={`u-col-12 u-col-lg-12`}>
              <MainBtn
                color='black'
                text='continue shopping'
                onClick={() => { navigate('/') }}
              />
            </div>
          </div>
          }

          { this.state.products.length > 0 &&
          <div>
            <div>You have {this.state.products.length} favorite{this.state.products.length > 1 ? `s` : ''}</div>
            <div className='u-height--20' />
          </div>
          }
        </div>
        <div className='u-height--20' />
        <div className={`u-col-md-10 u-offset-md-1`}>
          <div className='u-row u-pad-x--md'>{ products }</div>
        </div>
      </div>
    )
  }
}

export default FavoritesLayer
